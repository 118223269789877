<template>
  <div class="pageBox">
    <div>
      <div v-if="totalFont" class="totalFont">搜索到{{total}}条数据</div>
    </div>
    <div class="pageBox_right">
      <div class="el-pagination el-right">
        <el-button class="firstBtn" :disabled="isFirst" @click="onFirst">
          <i v-if="isFirst" class="iconfont icon-jiantou-zuozhi"></i>
          <i v-else class="iconfont icon-jiantou-zuozhi"></i>
        </el-button>
      </div>
      <el-pagination class="el-right" :current-page.sync="page" :page-size="pageSize" layout="prev,pager,next" :total="total" @current-change="handleCurrentChange" />
      <div class="el-pagination el-right">
        <el-button class="lastBtn" :disabled="isLast" @click="onLast">
          <i v-if="isFirst" class="iconfont icon-jiantou-youzhi"></i>
          <i v-else class="iconfont icon-jiantou-youzhi"></i>
        </el-button>
      </div>
      <el-pagination :current-page.sync="page" :page-size="pageSize" layout="jumper" :total="total" @current-change="handleCurrentChange" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    totalFont: {
      type: Boolean
    },
    total: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 8
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      isFirst: true,
      isLast: false,
    }
  },
  computed: {
    page: {
      get() {
        return this.currentPage
      },
      set(){
        
      }
    }
    // page() {
    //   return this.currentPage
    // }
  },
  methods: {
    handleCurrentChange(val) {
      const pages = Math.ceil(this.total / this.pageSize)
      if (val === 1) {
        this.isFirst = true
      } else {
        this.isFirst = false
      }
      if (val === pages) {
        this.isLast = true
      } else {
        this.isLast = false
      }
      this.$emit('onPageChange', val)
    },
    onFirst() {
      this.page = 1
      this.handleCurrentChange(1)
    },
    onLast() {
      const page = Math.ceil(this.total / this.pageSize)
      this.page = page
      this.handleCurrentChange(page)
    }
  }
}
</script>
<style scoped>
  .el-right {
    margin-right: 0 !important;
  }
  .el-pagination i {
    font-size: 10px;
    color: #339c9b;
  }
  ::v-deep .el-pager li.active {
    background-color: #339c9b;
  }
  .totalFont {
    line-height: 16px;
    font-size: 12px;
    color: #92a2bc;
  }
  .pageBox {
    display: flex;
    justify-content: space-between;
  }
  .pageBox_right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .firstBtn,
  .lastBtn {
    width: 20px;
    height: 20px;
    line-height: 20px;
    border: 0;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 2px 4px 0px rgba(52, 88, 157, 0.2);
  }
  .el-pagination .firstBtn,
  .el-pagination .lastBtn,
  .lastBtn ::v-deep span:not([class*='suffix']),
  .firstBtn ::v-deep span:not([class*='suffix']) {
    position: relative;
    min-width: auto;
    height: 20px;
    line-height: 20px;
  }
  button img {
    width: 7px;
    vertical-align: middle;
  }
  .firstBtn:not(:disabled) ::v-deep span {
    line-height: 18px;
  }
  .firstBtn:not(:disabled) img {
    transform: rotate(-180deg);
  }
  .lastBtn:disabled ::v-deep span {
    line-height: 18px;
  }
  .lastBtn:disabled img {
    transform: rotate(-180deg);
  }

  .icon-jiantou-zuozhi {
    position: absolute;
    right: -6px;
  }

  .icon-jiantou-youzhi {
    position: absolute;
    right: -6px;
  }
  @media screen and (max-width: 1664px) {
    .el-pagination i {
      font-size:10px;
      color: #339c9b;
      /* transform: scale(0.83); */
    }
    .firstBtn,
    .lastBtn {
      width: 13px;
      height: 13px;
      line-height: 13px;
      border: 0;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0px 2px 4px 0px rgba(52, 88, 157, 0.2);
    }
    .el-pagination .firstBtn,
    .el-pagination .lastBtn,
    .lastBtn ::v-deep span:not([class*='suffix']),
    .firstBtn ::v-deep span:not([class*='suffix']) {
      position: relative;
      min-width: auto;
      height: 13px;
      line-height: 13px;
    }
    button img {
      width: 4px;
      vertical-align: middle;
    }
    .firstBtn:not(:disabled) ::v-deep span {
      line-height: 12px;
    }
    .lastBtn:disabled ::v-deep span {
      line-height: 12px;
    }
    .icon-jiantou-zuozhi {
      position: absolute;
      right: -5px;
    }
    .icon-jiantou-youzhi {
      position: absolute;
      right: -6px;
    }
  }
</style>

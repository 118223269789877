// 引入封装好的axios
import axios ,{ apiUrl } from "./request";
//定义跨域代理路由

// const api = process.env.NODE_ENV == "development" ? '/devapi' : '/api'
// const api = process.env.NODE_ENV == "development" ? '/devapi' :'https://www.o2c-scf.com/api'
const api = process.env.NODE_ENV == "development" ? '/devapi' : apiUrl()
//公海数据一览
export const getPubPriSeaList = (params)=>{
    return axios.post(api + '/pubprisea/getPubPriSeaList?' + params)
}

//我的公海图表
export const findPubCount = (params)=>{
    return axios.post(api + '/pubprisea/findPubCount')
}

//立即邀约
export const managementInvitation = (params)=>{
    return axios.post(api + '/financingComparison/managementInvitation' , params)
}

//我的私海图表
export const findPriCount = (params)=>{
    return axios.post(api + '/pubprisea/findPriCount')
}

// 洽谈详情，放款详情，贷款详情
export const getFinancingCompList = (params)=>{
    return axios.post(api + '/financingComparison/getFinancingCompList?' + params)
}
// 下拉列表
export const findCodeInfoByKey = (params)=>{
    return axios.post(api + '/codeInfo/findCodeInfoByKey?' + params)
}

// 撤销
export const updateManagePubPriSeaFlag = (params)=>{
    return axios.post(api + '/financingComparison/updateManagePubPriSeaFlag?' + params)
}

//重复邀约
export const mInvitation = (params)=>{
    return axios.post(api + '/financingComparison/mInvitation?' + params)
}

// 反馈提交
export const updateFinancingComparisonInfo = (params)=>{
    return axios.post(api + '/financingComparison/updateFinancingComparisonInfo?' , params)
}

//同意邀约
export const updateFinancingComparisonStatue = (params)=>{
    return axios.post(api + '/financingComparison/updateFinancingComparisonStatue?' + params)
}

//合同处理
export const contract = (params)=>{
    return axios.post(api + '/financingComparison/contract?' + params)
}

//合同确认
export const sureContract = (params)=>{
    return axios.post(api + '/financingComparison/sureContract?' + params)
}

// 发票详情
export const findInvoiceInfoByFinancingNum = (params)=>{
    return axios.post(api + '/pubprisea/findInvoiceInfoByFinancingNum?' + params)
}

// 立即放款
export const loan = (params)=>{
    return axios.post(api + '/financingComparison/loan?' , params)
}

// 合同文件名称列表
export const findContractConfigList = (params)=>{
    return axios.post(api + '/personal/findContractConfigList?' + params)
}

// 上传合同
export const uploads = (params)=>{
    return axios.post(api + '/upload/uploads?' , params, { type: "upload" })
}

// // 上传合同
// export const editContractConfig = (params)=>{
//     return axios.post(api + '/personal/editContractConfig?' , params, { type: "upload" })
// }

// 下载合同
export const downloadContractSupplier = (params)=>{
    return axios.post(api + '/supplier/downloadContractSupplier?' + params)
}

// 下载合同name
export const getContractSupplier = (params)=>{
    return axios.post(api + '/supplier/getContractSupplier?' + params)
}

// 驳回合同
export const noContract = (params)=>{
    return axios.post(api + '/financingComparison/noContract?' + params)
}

// 我的投资
export const getManageInvestmentList = (params)=>{
    return axios.post(api + '/manage/getManageInvestmentList?' + params)
}

// 投资详情的表格
export const getManageInvestmentInfoById = (params)=>{
    return axios.post(api + '/manage/getManageInvestmentInfoById?' + params)
}

// 还款记录
export const getFinancingComparisonById = (params)=>{
    return axios.post(api + '/financingComparison/getFinancingComparisonById?' + params)
}

// 我的投资图表
export const findInvestmentCounts = (params)=>{
    return axios.post(api + '/manage/findInvestmentCounts')
}

// 我的风险池数据
export const findRiskCount = (params)=>{
    return axios.post(api + '/manage/findRiskCount')
}

// 我的工作台
export const capital = (params)=>{
    return axios.post(api + '/statistic/capital')
}

// 我的工作台图表(公海 投资金额)
export const pubSeaApplyInvestAmount = (params)=>{
    return axios.post(api + '/statistic/capital/pubSeaApplyInvestAmount')
}

// 我的工作台图表(公海 利率)
export const pubSeaApplyInvestRate = (params)=>{
    return axios.post(api + '/statistic/capital/pubSeaApplyInvestRate')
}

// 我的工作台图表(公海 账期)
export const pubSeaApplyInvestTerm = (params)=>{
    return axios.post(api + '/statistic/capital/pubSeaApplyInvestTerm')
}

// 我的工作台图表(公海 申请趋势)
export const pubSeaApplyTrend = (params)=>{
    return axios.post(api + '/statistic/capital/pubSeaApplyTrend')
}

// 我的工作台图表(风险池 逾期分布)
export const riskPoolOverdueDistribution = (params)=>{
    return axios.post(api + '/statistic/capital/riskPoolOverdueDistribution')
}

// 我的工作台图表(风险池 十大逾期投资)
export const riskPoolOverdueInvest = (params)=>{
    return axios.post(api + '/statistic/capital/riskPoolOverdueInvest')
}

// 我的工作台图表(我的投资 投资金额)
export const investAmount = (params)=>{
    return axios.post(api + '/statistic/capital/investAmount')
}

// 我的工作台图表(我的投资 投资利率)
export const investRate = (params)=>{
    return axios.post(api + '/statistic/capital/investRate')
}

// 我的工作台图表(我的投资 我的回款天数)
export const investCollectionDays = (params)=>{
    return axios.post(api + '/statistic/capital/investCollectionDays')
}

// 我的工作台图表(我的投资 投资趋势)
export const investTrend = (params)=>{
    return axios.post(api + '/statistic/capital/investTrend')
}

// 风险池(风险预警)
export const findRiskWarning = (params)=>{
    return axios.post(api + '/manage/findRiskWarning')
}

// 发票详情(发票图片)
export const getInvoiceUrl = (params)=>{
    return axios.post(api + '/invoice/getInvoiceUrl?' + params)
}

// 还款凭证
export const showLoan = (params)=>{
    return axios.post(api + '/supplierloan/showLoan?' + params)
}

// 未还款详情的确认
export const saveCollectionVoucher = (params)=>{
    return axios.post(api + '/manage/saveCollectionVoucher?' , params)
}

// 偏好设置
export const findPreferenceList = (params)=>{
    return axios.post(api + '/functionDefinition/findPreferenceList?' , params)
}

// 更新偏好设置
export const updatePreferenceList = (params)=>{
    return axios.post(api + '/functionDefinition/updatePreferenceList?' , params)
}

// 提醒设置
export const updatePaymentSettings = (params)=>{
    return axios.post(api + '/functionDefinition/updatePaymentSettings?' , params)
}

// 重置功能定义
export const deletePreferenceList = (params)=>{
    return axios.post(api + '/functionDefinition/deletePreferenceList?' , params)
}

// 重置功能定义
export const findFirmInfoByKey = (params)=>{
    return axios.post(api + '/functionDefinition/findFirmInfoByKey?' + params)
}

// 省份
export const getCPCity = (params)=>{
    return axios.post(api + '/personal/getCPCity?' + params)
}

// 设置默认偏好
export const defaultPreference = (params)=>{
    return axios.post(api + '/functionDefinition/defaultPreference?' + params)
}
